import React, {FC, useEffect, useState} from 'react'
import {useAppDispatch} from '../../../redux/storeHooks'
import {getAvailablePlatformsAsync} from '../../../redux/actions/platformActions'
import {Platform} from '../../../_interfaces/platform.interface'
import AvailablePlatformCard from './components/AvailablePlatformCard'
import ConnectPlatformModal from './components/ConnectPlatformModal'
import Loader from '../../../utils/Loader'

const Integrations:FC = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [availablePlatforms, setAvailablePlatforms] = useState<Platform[]>([])
  const [connectingPlatformData, setConnectingPlatformData] = useState<Platform | null>(null)
  const [openConnectPlatformModal, setOpenConnectPlatformModal] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    dispatch(getAvailablePlatformsAsync('')).then((res) => {
      console.log('available platforms', res.payload)
      if(res.payload?.data?.length){
        setAvailablePlatforms(res.payload?.data)
      }
      setLoading(false)
    })
    .catch((error)=>{
      console.log('error', error)
      setLoading(false)
    })
  }, [])

  if(loading) return <Loader/>

  return (
    <div>
      <h1>Available Connections</h1>
      <div className='flex flex-wrap g-5 mt-8'>
        {availablePlatforms.length
          ? availablePlatforms.map((availPlatform) => {
              return (
                <AvailablePlatformCard
                  key={availPlatform.name}
                  platform={availPlatform}
                  onClickToConnect={(platform) => {
                    setConnectingPlatformData(platform)
                    setOpenConnectPlatformModal(true)
                  }}
                />
              )
            })
          : null}
      </div>
      <ConnectPlatformModal
        isVisible={openConnectPlatformModal}
        onClose={() => {
          setOpenConnectPlatformModal(false)
          setConnectingPlatformData(null)
        }}
        platformData={connectingPlatformData}
      />
    </div>
  )
}

export default Integrations
