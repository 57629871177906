import React, {useEffect, useState} from 'react'
import {useAppDispatch} from '../../../redux/storeHooks'
import {getCatalogDataAsync} from '../../../redux/actions/catalogActions'
import BreadCrumbs from '../../../utils/BreadCrumbs'
import {CatalogType} from '../../../_interfaces/catalog.interface'
import DataTable, {ConditionalStyles, TableColumn} from 'react-data-table-component'
import AppDateRangePicker from '../../components/AppDateRangePicker'
import moment from 'moment'

const Catalog = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [openDateRangePicker, setOpenDateRangePicker] = useState<boolean>(false)
  const today1 = new Date();
  const sevenDaysAgo1 = new Date(today1);
  sevenDaysAgo1.setDate(today1.getDate() - 7);
  const [catalogFilter, setCatalogFilter] = useState({
    startDate: moment(sevenDaysAgo1.toString()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') ,
    endDate: moment(new Date().toDateString()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    sortBy: '',
    sortDirection: '',
    page:1,
    page_size:10
  })
  const [catalogData, setCatalogData] = useState<CatalogType[]>([])
  const [totalRecords, setTotalRecords] = useState<number>(0)

  const conditionalRowStyles: ConditionalStyles<CatalogType>[] = [
    {
      when: (row) => row.stock_info.type == 'balanced',
      classNames: ['bg-danger'],
      style: (row) => ({
        backgroundColor: row.stock_info.color,
        // color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      }),
    },
    {
      when: (row) => row.stock_info.type == 'below_thershlod',
      classNames: ['bg-warning'],
      style: (row) => ({
        backgroundColor: row.stock_info.color,
        // color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      }),
    },
    {
      when: (row) => row.stock_info.type == 'overstock',
      classNames: ['bg-danger'],
      style: (row) => ({
        backgroundColor: row.stock_info.color,
        // color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      }),
    },
  ]
  

  useEffect(() => {
    setLoading(true)
    dispatch(getCatalogDataAsync({...catalogFilter}))
    .then((res)=>{
      if(res.payload?.data?.length){
        setCatalogData(res.payload?.data)
        
      }
      setTotalRecords(res.payload?.total_records)
      setLoading(false)
    })
    .catch((error)=>{
      console.log('error', error)
      setLoading(false)
    })
  }, [catalogFilter])

  const columns: TableColumn<CatalogType>[] = [
    {
      name: '',
      cell: (row) => {
        return (
          <div>
            <i className='bi bi-info-circle-fill fs-3'></i>
          </div>
        )
      },
      width: 'max-content',
    },
    {
      name: 'Product Name',
      cell: (row) => row?.product_name,
    },
    {
      name: 'SKU',
      cell: (row) => row?.inventory_sku,
    },
    {
      name: 'Vendor Name',
      cell: (row) => row?.vendor,
    },
    {
      name: 'Total Sales',
      cell: (row) => row?.total_sales_quantity,
    },
    {
      name: 'Current Aval. Qty.',
      cell: (row) => row?.current_quantity,
    },
    {
      name: 'Predicted Qty.',
      cell: (row) => row?.predicted_quantity,
      sortable: true,
      sortField:"predicted_quantity",
    },

    {
      name: 'Warehouse',
      cell: (row) => row?.location,
    },
    {
      name: 'Lead Time',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <div className='d-flex align-items-center'>
              <input className='form-control form-control-sm h-20px' type='text' />
              <div className='d-flex flex-column'>
                <i className='bi bi-check-square-fill text-success cursor-pointer fs-2 h-100 ms-2'></i>
                {/* <i className='bi bi-x-square-fill text-danger cursor-pointer h-100 ms-2 mt-1'></i> */}
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  const clearFilter = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    setCatalogFilter({
      startDate: moment(sevenDaysAgo.toString()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') ,
      endDate: moment(new Date().toDateString()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      sortBy: '',
      sortDirection: '',
      page:1,
      page_size:10
    })
  }

  const handleSort = (sortColumn:string, sortDirection:string) => {
      setCatalogFilter(pre=>({...pre, sortBy:sortColumn, sortDirection:sortDirection }))
  }

  return (
    <div>
      <BreadCrumbs breadCrumbs={[]} currentActive='Catalog' />
      <div className='card mt-4'>
        <div className='card-header'>
          <div className='card-title'></div>
          <div className='card-toolbar'>
            <button
              className='btn btn-sm btn-secondary'
              onClick={() => setOpenDateRangePicker(true)}
            >
              {catalogFilter.startDate && catalogFilter.endDate
                ? `${moment(catalogFilter.startDate).format('YYYY/MM/DD')}  -  ${moment(
                    catalogFilter.endDate
                  ).format('YYYY/MM/DD')}`
                : 'Pick a Date Range'}
            </button>
            <AppDateRangePicker
              isVisible={openDateRangePicker}
              onCloseRangePicker={() => {
                setOpenDateRangePicker(false)
              }}
              onPickRange={(ev) => {
                setCatalogFilter((pre) => ({...pre, startDate: ev.startDate, endDate: ev.endDate}))
              }}
            />
            <button className='btn btn-sm btn-warning ms-2' onClick={() => {clearFilter()}}>
              Reset
            </button>
          </div>
        </div>
        <div className='card-body'>
          <DataTable
            key={"catalog"}
            responsive
            columns={columns}
            data={catalogData}
            progressPending={loading}
            persistTableHead
            pagination
            paginationServer
            fixedHeader
            paginationTotalRows={totalRecords}
            onChangePage={(pageNum, totalRows)=>{
              setCatalogFilter((pre)=>{
                return {...pre, page:pageNum}
              })
            }}
            onChangeRowsPerPage={(rowNumbers)=>{
              setCatalogFilter((pre)=>{
                return {...pre, page_size:rowNumbers}
              })
            }}
            onSort={(columnData, sortDirection) => {
              handleSort(columnData.sortField as string, sortDirection )
            }}
            customStyles={{
              table:{
                style:{
                  height:'52vh'
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Catalog
