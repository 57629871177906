import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import useAppToast from '../../../hooks/useAppToast'
import {useAppDispatch, useAppSelector} from '../../../redux/storeHooks'
import Loader from '../../../utils/Loader'
import {Modal} from 'react-bootstrap'
import { getLoggedInUserDetailAsync, updatePasswordAsync, updateProfileDetailAsync } from '../../../redux/actions/authActions'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
}

const initialValuesForPasswordChange = {
  previousPassword: '',
  password: '',
  confirmPassword: '',
}

const profileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
})

const forgotPasswordSchema = Yup.object().shape({
  previousPassword: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Previous password is required'),
  password: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .oneOf([Yup.ref('password')], 'Password and Confirm password field must be same')
    .required('Confirm Password is required'),
})

const Profile:FC = () => {
  const [loading, setLoading] = useState(false)
  const [loadingPassword, setLoadingPassword] = useState(false)
  const {userDetail} = useAppSelector((state) => state.auth)
  const toast = useAppToast()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const params = useParams()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      if(userDetail){
        setLoading(true)
        dispatch(updateProfileDetailAsync({
          userId:userDetail?.userId+'',
          payload:{
            first_name:values?.firstName,
            last_name:values?.lastName
          }
        })).then((res)=>{
          console.log('update profile data', res.payload);
          if(res.payload?.status==202){
            dispatch(getLoggedInUserDetailAsync()).then().catch()
            toast.successToast(res.payload?.message)
          }
          if(res.payload?.status==404||res.payload?.status==400){
            toast.warningToast(res.payload?.message)
          }
          if(res.payload?.status==500){
            toast.errorToast(res.payload?.message)
          }
          setLoading(false)
        })
      }
    },
  })

  const formikForChangePass = useFormik({
    initialValues: initialValuesForPasswordChange,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoadingPassword(true)
      dispatch(updatePasswordAsync({
        previousPassword:values.previousPassword,
        newPassword:values.password,
        confirmPassword:values.confirmPassword
      }))
      .then((res)=>{
        console.log('res', res)
        if(res.payload?.status==200){
          toast.successToast(res.payload?.message)
          resetForm()
          setShowChangePasswordModal(false)
        }
        if(res.payload?.status==400||res.payload?.status==404){
          toast.warningToast(res.payload?.data?.message)
        }
        if(res.payload?.status==200){
          toast.errorToast(res.payload?.data?.message)
        }
        setLoadingPassword(false)
      })
    },
  })

 

  useEffect(() => {
    if (formik) {
      formik.setFormikState((pre) => {
        return {
          ...pre,
          values: {
            ...pre.values,
            firstName: userDetail?.firstName ?? '',
            lastName: userDetail?.lastName ?? '',
            email: userDetail?.email ?? '',
          },
        }
      })
    }
  }, [userDetail])


  return (
    <div className='card col-sm-8 mx-auto'>
      <div className='card-body'>
        <div className=' mb-10'>
          <div>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder my-0'>
              {userDetail?.firstName} {userDetail?.lastName}{' '}
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-500 fw-semibold fs-6'>
              Want to update password ?
              <button
                className='btn btn-sm btn-text btn-text-info px-1 fw-bold'
                onClick={() => setShowChangePasswordModal(true)}
              >
                Update Password
              </button>
            </div>
            {/* end::Link */}
          </div>
          <div></div>
        </div>
        <form
          className='form fv-plugins-bootstrap5 fv-plugins-framework  p-sm-10 mx-sm-20'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='col-12'>
             {/* email section start  */}
             <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-500 fs-6'>Email <sub className='' >(Not Changeable)</sub> </label>
              <input
                type='email'
                disabled
                // defaultValue={formik.values?.email}
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent ',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* email section start  */}
            {/* firstname section start  */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>First Name</label>
              <input
                type='text'
                placeholder='First Name'
                defaultValue={userDetail?.firstName ?? ''}
                autoComplete='off'
                {...formik.getFieldProps('firstName')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                  {
                    'is-valid': formik.touched.firstName && !formik.errors.firstName,
                  }
                )}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.firstName}</span>
                  </div>
                </div>
              )}
            </div>
            {/* firstname section start  */}

            {/* last name section start  */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Last Name</label>
              <input
                type='text'
                placeholder='Last Name'
                autoComplete='off'
                {...formik.getFieldProps('lastName')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                  {
                    'is-valid': formik.touched.lastName && !formik.errors.lastName,
                  }
                )}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.lastName}</span>
                  </div>
                </div>
              )}
            </div>
            {/* last name section start  */}

           
          </div>

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-end pb-lg-0'>
            <button type='submit' className='btn btn-primary me-4 btn-sm'  data-kt-indicator={ loading ? "on" : '' } >
              <span className='indicator-label'>Update Profile</span>
              <span className="indicator-progress">
                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
               
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
      <Modal show={showChangePasswordModal}>
        <Modal.Header>Change Your Password</Modal.Header>
        <form onSubmit={formikForChangePass.handleSubmit}>
          <Modal.Body>
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6 required'>Previous Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formikForChangePass.getFieldProps('previousPassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formikForChangePass.touched.previousPassword &&
                      formikForChangePass.errors.previousPassword,
                  },
                  {
                    'is-valid':
                      formikForChangePass.touched.previousPassword &&
                      !formikForChangePass.errors.previousPassword,
                  }
                )}
              />
              {formikForChangePass.touched.previousPassword &&
                formikForChangePass.errors.previousPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formikForChangePass.errors.previousPassword}</span>
                    </div>
                  </div>
                )}
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6 required'>Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formikForChangePass.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formikForChangePass.touched.password && formikForChangePass.errors.password,
                  },
                  {
                    'is-valid':
                      formikForChangePass.touched.password && !formikForChangePass.errors.password,
                  }
                )}
              />
              {formikForChangePass.touched.password && formikForChangePass.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formikForChangePass.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6 required'>Confirm Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formikForChangePass.getFieldProps('confirmPassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formikForChangePass.touched.confirmPassword &&
                      formikForChangePass.errors.confirmPassword,
                  },
                  {
                    'is-valid':
                      formikForChangePass.touched.confirmPassword &&
                      !formikForChangePass.errors.confirmPassword,
                  }
                )}
              />
              {formikForChangePass.touched.confirmPassword &&
                formikForChangePass.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formikForChangePass.errors.confirmPassword}</span>
                    </div>
                  </div>
                )}
            </div>
            {/* end::Form group */}
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex flex-wrap justify-content-end pb-lg-0'>
              <button disabled={loadingPassword} type='submit' className='btn btn-primary me-4 btn-sm'>
                <span className='indicator-label'>Set new Password</span>
                {loadingPassword && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <button
                type='button'
                className='btn btn-light btn-sm'
                disabled={loadingPassword}
                onClick={() => setShowChangePasswordModal(false)}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default Profile
