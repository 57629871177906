import React from 'react'

type AppSwitchInputPropsType = {
    label:string
    isChecked:boolean
    onChange : React.ChangeEventHandler<HTMLInputElement>
}

const AppSwitchInput:React.FC<AppSwitchInputPropsType> = ({label,onChange,isChecked}) => {
  return (
    <div>
      <div className='form-check form-switch form-check-custom d-flex justify-content-between'>
        <label className='form-label me-2' htmlFor='flexSwitch30x50'>
          {label}
        </label>
        <input
          className='form-check-input w-45px h-25px'
          type='checkbox'
          checked={isChecked}
          id='flexSwitch30x50'
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default AppSwitchInput
