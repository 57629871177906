import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import AppSwitchInput from '../../components/AppSwitchInput'
import {useAppDispatch} from '../../../redux/storeHooks'
import {
  getPlatformSettingsAsync,
  updateSettingStatusAsync,
} from '../../../redux/actions/platformActions'
import useAppToast from '../../../hooks/useAppToast'
import {Grouped_Warehouse_Connection} from '../../../_interfaces/platform.interface'
import Loader from '../../../utils/Loader'

const AllWarehousePage: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const toast = useAppToast()
  const [groupedSettings, setGroupedSettings] = useState<Grouped_Warehouse_Connection[]>([])

  const getWarehouseData = () => {
    setLoading(true)
    dispatch(getPlatformSettingsAsync([]))
      .then((response) => {
        console.log('platform settings------>', response.payload)
        if (response.payload?.data?.length) {
          setGroupedSettings(response.payload?.data)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getWarehouseData()
  }, [])

  if (loading) return <Loader />

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-body p-sm-4 p-0'>
          <div className='row'>
            {groupedSettings.length ? (
              groupedSettings.map((setting, settingIndex) => {
                return (
                  <div className='mt-8 mx-auto p-4 ' key={'sectiongroup_' + setting.platform_name}>
                    <p className='h2'> Connection Name - {setting.friendly_name}</p>
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 gx-2 gy-2 mt-2'>
                      {setting.object_settings.length ? (
                        setting.object_settings.map((item, itemIndex) => {
                          return (
                            <div className='col mt-2'>
                              <div className='card card-custom shadow'>
                                <div className='card-body'>
                                  <div className='d-flex justify-content-between align-items-end'>
                                    <p className='h2'>{item.object_data.api_name}</p>
                                    <AppSwitchInput
                                      label=''
                                      isChecked={item.status}
                                      onChange={(ev) => {
                                        let shadow = [...groupedSettings]
                                        let currentSetting = groupedSettings[settingIndex]
                                        let currentItem = currentSetting.object_settings[itemIndex]
                                        currentItem.status = ev.target.checked
                                        currentSetting.object_settings[itemIndex] = currentItem
                                        shadow[settingIndex] = currentSetting
                                        dispatch(
                                          updateSettingStatusAsync({settings_id : item.id})
                                        ).then((res) => {
                                          console.log('systus change res', res.payload)
                                          if (res.payload?.status == 200) {
                                            toast.successToast('Status Changed')
                                          }
                                        })
                                        setGroupedSettings(shadow)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <div>
                          <h2>No Settings found</h2>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <div>
                <h2>No Settings found</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllWarehousePage
