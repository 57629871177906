import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'


export const getAvailablePlatformsAsync = createAsyncThunk(
  'auth/getAvailablePlatformsAsync',
  async function(params:any, toolkit) {
    return await AxiosClient('GET', '/api/available_platform/', [], toolkit, params)
  }
)

export const connectPlatformAsync = createAsyncThunk(
  'auth/connectPlatformAsync',
  async function(payload:any, toolkit) {
    return await AxiosClient('POST', '/api/platform_credentials/', payload, toolkit, [])
  }
)

export const updatePlatformAsync = createAsyncThunk(
  'auth/updatePlatformAsync',
  async function( payload: { activePlatformId:number, payload:any}, toolkit) {
    return await AxiosClient('PUT', `/api/platform_credentials/${payload.activePlatformId}`, payload.payload, toolkit, [])
  }
)

export const getActivePlatformsAsync = createAsyncThunk(
  'auth/getActivePlatformsAsync',
  async function(_, toolkit) {
    return await AxiosClient('GET', '/api/user_platform/', [], toolkit, [])
  }
)

export const deleteActivePlatformsAsync = createAsyncThunk(
  'auth/deleteActivePlatformsAsync',
  async function(params:{activePlatformId:number}, toolkit) {
    return await AxiosClient('DELETE', `/api/platform_credentials/${params.activePlatformId}`, [], toolkit, [])
  }
)


export const getPlatformSettingsAsync = createAsyncThunk(
  'auth/getPlatformSettingsAsync',
  async function(params:any, toolkit) {
    return await AxiosClient('GET', `/api/platform_settings`, [], toolkit, params)
  }
)

export const updateSettingStatusAsync = createAsyncThunk(
  'auth/updateSettingStatusAsync',
  async function(params:any, toolkit) {
    return await AxiosClient('PUT', `/api/platform_settings`, [], toolkit, params)
  }
)

export const getPlatformProductsAsync = createAsyncThunk(
  'auth/getPlatformProductsAsync',
  async function(params:{activeSettingId:any}, toolkit) {
    return await AxiosClient('GET', `/api/predication_report/${params.activeSettingId}`, [], toolkit, [])
  }
)


 