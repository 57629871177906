import {ApexOptions} from 'apexcharts'
import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import DataTable, {TableColumn} from 'react-data-table-component'
import {useAppDispatch} from '../../../redux/storeHooks'
import {getAllSalesAsync} from '../../../redux/actions/productActions'
import {Sales_Record} from '../../../_interfaces/catalog.interface'

const Dashboard = () => {
  const series = [25, 15, 44,]

  const [loading, setLoading] = useState(false)
  const [salesData, setSalesData] = useState<Sales_Record[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    setLoading(true)
    dispatch(getAllSalesAsync({page_size: 1}))
      .then((response) => {
        console.log('sale response', response.payload)
        if (response.payload?.data?.length) {
          setSalesData(response.payload?.data)
        }

        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const options: ApexOptions = {
    chart: {
      width: '60%',
      height: '100%',
      type: 'donut',
      // toolbar:{
      //   show:true
      // },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: 'slateblue',
        opacity: 0.35,
      },
    },
    labels: [
      `Variants - 700`,
      `Variants in Stock - 280`,
      `Stock Units - 9600`,
      // `Stock Cost - $3404234`,
      // `Stock Retail - $12345`,
    ],
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: 'left',
      fontWeight: 'bold',
      itemMargin: {
        vertical: 8,
      },
      markers: {
        height: 0,
        width: 0,
      },
    },
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
        dataLabels: {
          offset: -5,
        },
      },
    },
    grid: {
      position: 'back',
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 100,
      },
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 260,
            height: 300,
            type: 'donut',
          },

          legend: {
            position: 'top',
            horizontalAlign: 'left',
            fontSize: 10,
            fontWeight: 'bold',
            itemMargin: {
              vertical: 3,
            },
            markers: {
              height: 0,
              width: 0,
            },
          },

          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
              dataLabels: {
                offset: -5,
              },
            },
          },
        },
      },

      {
        breakpoint: 992,
        options: {
          chart: {
            width: 350,
            height: 350,
            type: 'donut',
          },

          legend: {
            position: 'top',
            horizontalAlign: 'left',
            fontWeight: 'bold',
            itemMargin: {
              vertical: 5,
            },
            markers: {
              height: 0,
              width: 0,
            },
          },

          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
              dataLabels: {
                offset: -5,
              },
            },
          },
        },
      },
    ],
  }

  const options2: ApexOptions = {
    chart: {
      width: '100%',
      height: '100%',
      type: 'pie',
    },
    labels: [
      `Variants - 700`,
      `Variants in Stock - 280`,
      `Stock Units - 9600`,
      `Stock Cost - $3404234`,
      `Stock Retail - $12345`,
    ],
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: 'left',
      fontWeight: 'bold',
      markers: {
        height: 0,
        width: 0,
      },
    },
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
        dataLabels: {
          offset: -5,
        },
      },
    },
    grid: {
      position: 'back',
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 100,
      },
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 260,
            height: 300,
            type: 'pie',
          },

          legend: {
            position: 'top',
            horizontalAlign: 'left',
            fontSize: 10,
            fontWeight: 'bold',
            itemMargin: {
              vertical: 3,
            },
            markers: {
              height: 0,
              width: 0,
            },
          },

          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
              dataLabels: {
                offset: -5,
              },
            },
          },
        },
      },

      {
        breakpoint: 992,
        options: {
          chart: {
            width: 350,
            height: 350,
            type: 'pie',
          },

          legend: {
            position: 'top',
            horizontalAlign: 'left',
            fontWeight: 'bold',
            itemMargin: {
              vertical: 5,
            },
            markers: {
              height: 0,
              width: 0,
            },
          },

          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
              dataLabels: {
                offset: -5,
              },
            },
          },
        },
      },
    ],
  }

  const columns: TableColumn<Sales_Record>[] = [
    {
      name: 'Title',
      cell: (row) => {
        return (
          <div>
            <p className='fs-5 text-success fw-bold mb-0'>{row?.api_title}</p>
            <p className='mb-0'>{row?.api_description}</p>
          </div>
        )
      },
    },
    {
      name: 'Vendor',
      cell: (row) => row?.api_vendor,
    },
    {
      name: 'Replenish',
      cell: (row) => row?.api_varient_count,
    },
    // {
    //   name: 'Cost',
    //   cell: (row) => row?.api_store_url,
    // },
    // {
    //   name: 'Retail',
    //   cell: (row) => row?.total_sales_quantity,
    // },
  ]

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Dashboard</h3>
          <div className='card-toolbar'>
            <button type='button' className='btn btn-sm btn-light'>
              Action
            </button>
          </div>
        </div>
        <div className='card-body'>
          <div className='row justify-content-between'>
            <div className='col-12 border-sm-end border-end-2 border-secondary'>
              <h4>Stock Retail By Vendor</h4>

              <div id='chart' className=''>
                <ReactApexChart options={options} series={series} type='donut' height={'300px'} />
              </div>
            </div>

            {/* <div className='col-md-6'>
              <h4>Stock Retail By Vendor</h4>
              <div id='chart' className='ps-2'>
                <ReactApexChart options={options2} series={series} type='pie' height={'300px'} />
              </div>
            </div> */}
          </div>
          <div className='separator border-secondary my-5'></div>
          <div className='row shadow'>
            <div className='card-header'>
              <div className='card-title'>Top Variants To Replenish</div>
            </div>
            <DataTable
              responsive
              highlightOnHover
              columns={columns}
              data={salesData}
              pagination
              persistTableHead
              progressPending={loading}
              customStyles={{
                table: {
                  style: {
                    minHeight: '40vh',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
