import { createAsyncThunk } from "@reduxjs/toolkit"
import AxiosClient from "../AxiosClient"



export const getCatalogDataAsync = createAsyncThunk(
    'auth/getCatalogDataAsync',
    async function(params:any, toolkit) {
      return await AxiosClient('GET', '/api/platform_catalog', [], toolkit, params)
    }
  )