import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import AppSwitchInput from '../../components/AppSwitchInput'
import {useAppDispatch} from '../../../redux/storeHooks'
import {getPlatformSettingsAsync, updateSettingStatusAsync} from '../../../redux/actions/platformActions'
import {useParams} from 'react-router-dom'
import useAppToast from '../../../hooks/useAppToast'
import { Warehouse_Connection } from '../../../_interfaces/platform.interface'
import Loader from '../../../utils/Loader'

const ActivePlatformSetting: FC = () => {
  const tabs = [
    {
      title: 'Connections & Warehouse',
      tabKey: 'Connection_Warehouse',
    },
    {
      title: 'Next Tabs',
      tabKey: 'Next_Tab',
    },
  ]

  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const params = useParams()
  const dispatch = useAppDispatch()
  const toast = useAppToast()
  const [settings, setSettings] = useState<Warehouse_Connection[]>([])


  useEffect(() => {
    if(params.platformId){
      setLoading(true)
      dispatch(getPlatformSettingsAsync({user_platform_id: Number(params.platformId)})).then((response) => {
        console.log('platform settings------>', response.payload)
        if(response.payload?.data?.length){
          setSettings(response.payload?.data)
        }
        setLoading(false)
      })
      .catch(()=>{
        setLoading(false)
      })
    }
  }, [])

  if(loading) return <Loader/>

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header p-0' style={{minHeight: '0'}}>
          <ul className='nav nav-tabs' id='activePlatformSetting' role='tablist'>
            {tabs.map((tabItem, tabIndex) => {
              return (
                <li className='nav-item' role='presentation'>
                  <button
                    className={clsx(
                      'nav-link',
                      activeTab == tabIndex
                        ? 'active fs-3 fw-bold text-info border border-secondary border-2 shadow-sm'
                        : 'text-dark pt-4 border border-light border-2 '
                    )}
                    id={'tabBtn_' + tabIndex}
                    data-bs-toggle='tab'
                    data-bs-target={'tabItem_' + tabIndex}
                    type='button'
                    role='tab'
                    aria-controls='home'
                    aria-selected='true'
                    onClick={() => setActiveTab(tabIndex)}
                  >
                    {tabItem.title}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
        <div className='card-body'>
          <div className='tab-content p-4' id='activePlatformSettingContent'>
            {tabs.map((tabData, tabDataIndex) => {
              return (
                <div
                  className={clsx('tab-pane fade show', activeTab == tabDataIndex ? 'active' : '')}
                  id={'tabItem_' + tabDataIndex}
                  role='tabpanel'
                  aria-labelledby='whareHouse'
                >
                  {tabData.tabKey == 'Connection_Warehouse' ? (
                    <>
                      <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 gx-2 gy-2 '>
                        { settings.length ?
                         settings.map((item, itemIndex) => {
                          return (
                            <div className='col'>
                              <div className='card card-custom shadow'>
                                <div className='card-body'>
                                  <div className='d-flex justify-content-between align-items-end'>
                                    <p className='h2'>{item.object_data.api_name}</p>
                                    <AppSwitchInput
                                      label=''
                                      isChecked={item.status}
                                      onChange={(ev) => {
                                        let shadow = [...settings]
                                        let currentItem = settings[itemIndex]
                                        if(ev.target.checked){
                                          currentItem.status = true
                                          shadow[itemIndex] = currentItem
                                          dispatch(updateSettingStatusAsync({ user_platform_id : item.user_platform,  settings_id:item.id}))
                                          .then((res)=>{
                                            console.log('systus change res', res.payload );
                                            if(res.payload?.status==200){
                                              toast.successToast("Status Changed")
                                            }
                                          })
                                          setSettings(shadow)
                                        }else{
                                          currentItem.status = false
                                          shadow[itemIndex] = currentItem
                                          dispatch(updateSettingStatusAsync({user_platform_id : item.user_platform,  settings_id:item.id}))
                                          .then((res)=>{
                                            console.log('systus change res', res.payload );
                                            if(res.payload?.status==200){
                                              toast.successToast("Status Changed")
                                            }
                                          })
                                          setSettings(shadow)
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }) : 
                         <div>
                          <h2>No Settings found</h2>
                         </div>
                        }
                      </div>
                    </>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivePlatformSetting
