// import {AsyncThunkConfig, GetThunkAPI} from '@reduxjs/toolkit/dist/createAsyncThunk'
import axios from 'axios'
import React, {FC, useState} from 'react'
import { accessTokenKeyName, refreshTokenKeyName } from '../utils/constants'
import { setLoggingData } from './slices/authSlice'
import useAppToast from '../hooks/useAppToast'


type REQUEST_METHODS = 'GET' | 'POST' | 'PUT' | 'DELETE'
type ENDPOINT = string
// type TOOLKIT = any
type PARAMS = Object
// type HEADER_OPTIONS = any

const getTokenSync = () => {
  return localStorage.getItem(accessTokenKeyName)
}

const AxiosClient = async (
  method: REQUEST_METHODS,
  endPoint: ENDPOINT,
  payload: any,
  toolkit: any,
  params: PARAMS
) => {
  const token = getTokenSync()
  const { warningToast } = useAppToast()
  // console.log('token', token)

  const headerOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'ngrok-skip-browser-warning':true,
    // 'Access-Control-Allow-Origin':'*',
    // 'User-Agent':'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36'
  }

  if (token) {
    headerOptions['Authorization'] = `Bearer ${token}`
  }

  try {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + endPoint,
      method: method,
      data: payload,
      params: {...params},
      headers: {...headerOptions},
    })
    return toolkit.fulfillWithValue(response.data)
  } catch (error:any) {
    console.log('error: axios client', error)

    //added this coz if token expire remove all tokens and local storage data
    // if(error?.response?.status===401 && token ){
    //   localStorage.removeItem(accessTokenKeyName)
    //   localStorage.removeItem(refreshTokenKeyName)
    //   localStorage.clear()
    //   toolkit.dispatch(setLoggingData({isLoggedIn: false, userData: null}))
    //   warningToast('Session is expired. Please login again')
    // }
    return toolkit.rejectWithValue(error.response)
  }
}


export { AxiosClient}
export default AxiosClient
