import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import AppSwitchInput from '../../components/AppSwitchInput'
import {useAppDispatch} from '../../../redux/storeHooks'
import {
  getPlatformSettingsAsync,
  updateSettingStatusAsync,
} from '../../../redux/actions/platformActions'
import {useParams} from 'react-router-dom'
import useAppToast from '../../../hooks/useAppToast'
import {Warehouse_Connection} from '../../../_interfaces/platform.interface'
import Loader from '../../../utils/Loader'
import AllWarehousePage from './AllWarehousePage'

const CommonSettings: FC = () => {
  const tabs = [
    {
      title: 'General Setting',
      tabKey: 'General_Setting',
    },
    {
      title: 'Connections & Warehouse',
      tabKey: 'Connection_Warehouse',
    },
  ]

  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const params = useParams()
  const dispatch = useAppDispatch()
  const toast = useAppToast()
  const [settings, setSettings] = useState<Warehouse_Connection[]>([])

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header p-0' style={{minHeight: '0'}}>
          <ul className='nav nav-tabs' id='activePlatformSetting' role='tablist'>
            {tabs.map((tabItem, tabIndex) => {
              return (
                <li className='nav-item' role='presentation'>
                  <button
                    className={clsx(
                      'nav-link',
                      activeTab == tabIndex
                        ? 'active fs-3 fw-bold text-info border border-secondary border-2 shadow-sm'
                        : 'text-dark pt-4 border border-light border-2 '
                    )}
                    id={'tabBtn_' + tabIndex}
                    data-bs-toggle='tab'
                    data-bs-target={'tabItem_' + tabIndex}
                    type='button'
                    role='tab'
                    aria-controls='home'
                    aria-selected='true'
                    onClick={() => setActiveTab(tabIndex)}
                  >
                    {tabItem.title}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
        <div className='card-body p-sm-4 p-0'>
          <div className='tab-content p-4' id='activePlatformSettingContent'>
            {tabs.map((tabData, tabDataIndex) => {
              return (
                <div
                  className={clsx('tab-pane fade show', activeTab == tabDataIndex ? 'active' : '')}
                  id={'tabItem_' + tabDataIndex}
                  role='tabpanel'
                  aria-labelledby='whareHouse'
                >
                  {tabData.tabKey == 'General_Setting' && activeTab==0 ? (
                    <>
                    <div className='row justify-content-center' >
                      <div className='col-sm-10'>
                      <div className='shadow-sm rounded p-4 mt-4' >
                        <div className='p-5' >
                           <AppSwitchInput
                            key={'notify mail'}
                            isChecked={true}
                            label='Notify updates using email'
                            onChange={(ev)=>{}}
                           />
                        </div>
                        
                          <div className='d-flex align-items-center p-5' >
                            <div className='col-10' >
                              <label className='fw-bold' htmlFor="">Set Threshold Value</label>
                            </div>
                            <div className='col-2' >
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                placeholder="Threshold Value"
                              />
                            </div>
                          </div>
                          <div className=' p-5' >
                            <div>
                              <label className='fw-bold' htmlFor="">Emails</label>
                            </div>
                            <div>
                              <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter comma seperated emails"
                              />
                            </div>
                          </div>

                        </div>
                         
                      </div>
                    </div>
                    </>
                  ) : null}

                  {tabData.tabKey == 'Connection_Warehouse' && activeTab==1 ? (
                    <>
                      <div className='row'>
                         <AllWarehousePage/>
                      </div>
                    </>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonSettings

{
  /* <div className='row  '>
<div className='card card-custom'>
  <div className='card-body'>
    <div className='card card-bordered'>
      <div className='card-header ribbon  ribbon-start'>
        <div className='ribbon-label bg-primary'>Ribbon</div>
        
      </div>

      <div className='card-body'>...</div>
    </div>
  </div>
</div>
</div> */
}
