import React, {FC} from 'react'
import {Platform} from '../../../../_interfaces/platform.interface'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type ActivePlatformCardPropsType = {
  activePlatform: Platform
  onClickEdit: () => void
  onClickDelete: () => void
}

const ActivePlatformCard: FC<ActivePlatformCardPropsType> = ({
  activePlatform,
  onClickDelete,
  onClickEdit,
}) => {
  return (
    <div className='col-xl-2 col-md-4 col-sm-6 col-12'>
      <div>
        <div className='card border border-3 bg-hover-light-info'>
          {/* <div className='d-inline-flex justify-content-end' >
            <OverlayTrigger overlay={<Tooltip>View Products</Tooltip>} >
              <Link to={`/platform-products/${activePlatform.id}`} className='btn btn-icon' >
                <i className="bi bi-arrow-right-square-fill text-primary fs-2x"></i>
              </Link>
            </OverlayTrigger>
          </div> */}
          <div className='card-body p-2'>
            <div className='d-flex justify-content-center p-4'>
              <img
                src={activePlatform.logo_url}
                alt={activePlatform.friendly_name}
                className='h-80px'
                loading='lazy'
              />
            </div>
            <div className='separator my-2'></div>
            <div className='p-4 text-center'>
              <label htmlFor='' className='form-label text-muted'>
                Connection Name
              </label>
              <p className='h4 text-truncate'>{activePlatform.friendly_name}</p>
            </div>

            <div className='d-flex w-100 justify-content-around '>
              <button
                className='btn btn-sm btn-info '
                onClick={() => {
                  onClickEdit()
                }}
              >
                <i className="bi bi-pen-fill"></i>
              </button>
              <Link
                to={`/platform-setting/${activePlatform.id}`}
                className='btn btn-sm btn-primary '
              >
                <i className="bi bi-gear-fill"></i>
              </Link>
              <button className='btn btn-sm btn-danger ' onClick={() => onClickDelete()}>
                <i className='bi bi-trash-fill'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivePlatformCard
